import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Two Ropes Brainteaser",
  "author": "tzookb",
  "type": "post",
  "date": "2020-06-22T13:50:08.000Z",
  "url": "/two-ropes-brainteaser",
  "featuredImage": "./thumb.jpg",
  "desc": "Brainteaser - how to calculate 15 minutes with 2 ropes of one hour each burn time for each one. Each rope can burn in different lengths but in the same total time.",
  "categories": ["brainteaser", "coding-interview"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This nice brainteaser is quite nice to solve and think through. It took me some time to get to the solution but really nice!`}</p>
    <h2>{`Problem:`}</h2>
    <p>{`You have two ropes, and each takes exactly one hour to burn.
How would you use them to time exactly 15 minutes?
Note that the ropes are of uneven densities,
so half the rope length-wise does not necessarily take half an hour to burn.`}</p>
    <h3>{`Think about it...`}</h3>
    <p>{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`
...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`
...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br />{`...`}<br /></p>
    <h2>{`Solution:`}</h2>
    <p>{`As the densities of the ropes are different and we can really know how far and how long a burn too we should trick our way to the solution.
We will light one rope in two end and another in one end. Then as the first is burned down, we will light the second rope on the other hand.
Now we will have exactly 15 minutes. `}</p>
    <ul>
      <li parentName="ul">{`light rope 1 in two ends.`}</li>
      <li parentName="ul">{`light rope 2 in one ends`}</li>
      <li parentName="ul">{`rope 1 should take exactly 30 minutes to burn down completely`}</li>
      <li parentName="ul">{`as rope 1 burned down, rope 2 now has 30 mintues left`}</li>
      <li parentName="ul">{`we will light rope 2 on the other side`}</li>
      <li parentName="ul">{`it should take rope two half the time that was left before`}</li>
      <li parentName="ul">{`so now we will have 15 min until rope 2 will get burned completely`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      